import React, { Component } from "react";
import "./Project-Items.css";

class ProjectItems extends Component {
  render(props) {
    return (
      <div className="project-item-list">
        <ul className="project-list">
          {this.props.projectsdata.map((project, id) => {
            return (
              <li className="project-item" key={id}>
                <div className="project-details">
                  <h1 className="project-title">{project.title}</h1>
                  <p className="project-description">{project.description}</p>
                  <h3 className="tech-stack-header">Tech Stack</h3>
                  <ul className="tech-list">
                    {project.tech.map(function(tech, id) {
                      return (
                        <li className="tech-item" key={id}>
                          <p>{tech}</p>
                        </li>
                      );
                    })}
                  </ul>
                  <h3 className="role-header">Role</h3>
                  <p className="role">{project.role}</p>
                  <p className="role-task">{project.task}</p>
                  <h3 className="links-header">Links</h3>
                  <a
                    href={project.github}
                    target="_blank"
                    className="github-link"
                  >
                    Github
                  </a>
                  <a
                    href={project.livesite}
                    target="_blank"
                    className="livesite-link"
                  >
                    Livesite
                  </a>
                </div>
                <hr className="project-breaker" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ProjectItems;
