import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./Nav.css";

const Nav = () => {
  return (
    <Menu 
      pageWrapId={"page-wrap"} 
      outerContainerId={"outer-container"}
      right
    >
      <Link to="/" className="menu-item">
        Home
      </Link>
      <Link to="/about" className="menu-item">
        About
      </Link>
      <Link to="/background" className="menu-item">
        Background
      </Link>
      <Link to="/technologies" className="menu-item">
        Technologies
      </Link>
      <Link to="/projects" className="menu-item">
        Projects
      </Link>
      <Link to="/contact" className="menu-item">
        Contact
      </Link>
      <br />
      <a
        href="https://github.com/jMac029"
        target="_blank"
        className="nav__item nav__small social-link"
        rel="noopener noreferrer"
      >
        github
      </a>
      <a
        href="https://www.linkedin.com/in/jamesdmcmillan/"
        target="_blank"
        className="nav__item nav__small social-link"
        rel="noopener noreferrer"
      >
        linkedin
      </a>
      <a
        href="https://twitter.com/jMacTheWanderer"
        target="_blank"
        className="nav__item nav__small social-link"
        rel="noopener noreferrer"
      >
        twitter
      </a>
      <a
        href="https://stackoverflow.com/users/8365957/jmacthedev"
        target="_blank"
        className="nav__item nav__small social-link"
        rel="noopener noreferrer"
      >
        stackoverflow
      </a>
      <a
        href="https://www.instagram.com/jmacthewanderer/"
        target="_blank"
        className="nav__item nav__small social-link"
        rel="noopener noreferrer"
      >
        instagram
      </a>
      <br />
      <p className="nav-footer">&copy; james mcmillan 2025</p>
    </Menu>
  );
};

export default Nav;
