import React, { Component } from "react";
import "./Tech-Items.css";

class TechItems extends Component {
  render(props) {
    return (
      <div className="tech-items">
        <ul className="techlist">
          {this.props.techdata.map((tech, id) => {
            return (
              <li className="tech" key={id}>
                <p>{tech.tech.toLowerCase()}</p>
                {/* <p className="tech-details">
                  years_exp {tech.years} | {tech.skill_level} skill_level
                </p> */}
                {/* <hr className="tech-breaker" /> */}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default TechItems;
