import React, { Component } from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "../../css/style.css";
import "./Contact.css";

class Contact extends Component {
  render() {
    return (
      <div className="wrapper">
        <Nav />
        <Header />
        <div className="container">
          <h1 className="page-header">contact</h1>
          <form method="post">
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                <input type="text" name="name" placeholder="name" />
              </label>
            </p>
            <p>
              <label>
                <input type="email" name="email" placeholder="email" />
              </label>
            </p>
            <p>
              <label>
                <textarea name="message" placeholder="message" />
              </label>
            </p>
            <p>
              <button type="submit" className="submit-button">
                send
              </button>
            </p>
          </form>
          <br />
          <h1 className="page-header">social</h1>
          <ul className="social-link-list">
            <li>
              <a
                href="https://github.com/jMac029"
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                github
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/jamesdmcmillan/"
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                linkedin
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/jMacTheWanderer"
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                twitter
              </a>
            </li>
            <li>
              <a
                href="https://stackoverflow.com/users/8365957/jmacthedev"
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                stackoverflow
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/jmacthewanderer/"
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Contact;
