module.exports = [
    {
      "id": 1,
      "title": "EcomRush",
      "role": "Full Stack Developer",
      "task": "implemented twilio and passportjs, connected front and back ends, built reactjs components.",
      "description": "product review platform with emphasis on gathering quality reviews from verified purchasers.",
      "tech": [
          "MongoDB", "Expressjs", "Reactjs", "Nodejs", "Passportjs", "Twilio", "SendGrid"
      ],
      "github": "private",
      "livesite": "https://ecomrush.ngrok.io"  
    },
    {
      "id": 2,
      "title": "Catch of the Day",
      "role": "Full Stack Developer",
      "task": "built out react components and hoooked up to firebase back-end",
      "description": "online fish restaurant order application",
      "tags": [
        "MERN", "Full Stack", "heroku"
      ],
      "tech": [
        "Firebase", "Reactjs", "CSS", "netlify" 
      ],
      "github": "https://github.com/jMac029/catch-of-thee-day",
      "livesite": "https://catch-of-thee-day.netlify.com/"   
    },
    {
      "id": 3,
      "title": "Reactive Contact Search",
      "role": "Full Stack Developer",
      "task": "built from the ground up with reactjs",
      "description": "reactjs app to quickly filter through over 1000 contacts.",
      "tags": [
        "Reactjs", "Full Stack", "CSS", "netlify"
      ],
      "tech": [
        "Reactjs", "netlify"
      ],
      "github": "https://github.com/jMac029/reactive-contact-search",
      "livesite": "http://reactive-contact-search.netlify.com//"   
    },
    {
      "id": 4,
      "title": "Star Wars The Clicky Game",
      "role": "Full Stack Developer",
      "task": "build a simple memory card game using Reactjs",
      "description": "a memory game with React using React and Create-React-App",
      "tags": [
        "Reactjs", "Github Pages", "Create React App"
      ],
      "tech": [
        "Reactjs", "CSS", "Github Pages" 
      ],
      "github": "https://github.com/jMac029/react-clicky-game",
      "livesite": "https://jmac029.github.io/react-clicky-game/"   
    },
    {
      "id": 5,
      "title": "Reactive Reading List",
      "role": "Full Stack Developer",
      "task": "built from the ground up in the full MERN stack and deployed to heroku",
      "description": "application to keep track of your reading list",
      "tags": [
        "MERN", "Full Stack", "heroku"
      ],
      "tech": [
        "MongoDB", "Expressjs", "Reactjs", "Nodejs", "heroku" 
      ],
      "github": "https://github.com/jMac029/react-reading-list",
      "livesite": "https://reactive-reading-list.herokuapp.com/"   
    },
    {
      "id": 6,
      "title": "Apple Hearsay",
      "role": "Full Stack Developer",
      "task": "built from the ground up using Node, Express, MongoDB, Bootstrap and Handlebars",
      "description": "a web app that lets users view and leave comments on the latest apple rumors.",
      "tags": [
        "Bootstrap", "Web Scrapping", "Cherrio", "MongoDB", "Handlebarsjs", "Nodejs", "Expressjs", "heroku"
      ],
      "tech": [
        "MongoDB", "Bootstrap", "Handlebarsjs", "Nodejs", "Cherrio", "Expressjs", "heroku" 
      ],
      "github": "https://github.com/jMac029/apple-hearsay",
      "livesite": "https://apple-hearsay.herokuapp.com"   
    },
    {
      "id": 7,
      "title": "High Flow",
      "role": "Full Stack Developer | Project Manager",
      "task": "built out back-end api and connected up to front-end, ran the project to see it through.",
      "description": "a b-2-b platform for the budding recreation canabis industry.",
      "tags": [
        "Bootstrap", "MongoDB", "Pugjs", "Nodejs", "Expressjs"
      ],
      "tech": [
        "MongoDB", "Bootstrap", "Pugjs", "Nodejs", "Expressjs", "heroku" 
      ],
      "github": "https://github.com/jMac029/HighFlow",
      "livesite": "https://highflow.herokuapp.com"   
    },
    {
      "id": 8,
      "title": "Meander Mate",
      "role": "Full Stack Developer",
      "task": "built from the ground up with Node, Express, jQuery and Bootstrap",
      "description": "a compatibility-based 'FriendFinder' application using node and express.",
      "tags": [
        "Bootstrap", "Node", "Express", "jQuery", "heroku"
      ],
      "tech": [
        "Nodejs", "Expressjs", "jQuery", "Bootstrap", "heroku" 
      ],
      "github": "https://github.com/jMac029/MeanderMate",
      "livesite": "https://meander-mate.herokuapp.com/"   
    },
    {
      "id": 9,
      "title": "Bad Libs",
      "role": "Full Stack Developer | Project Manager",
      "task": "built out the back-end and API calls to Giphy along with logic for questions, connected up to the front-end, and deployment.",
      "description": "A fun take on the class Mad Libs game now with the power of Giphy.",
      "tags": [
        "Foundation", "jQuery", "Game", "Giphy", "API"
      ],
      "tech": [
        "Foundation", "jQuery", "HTML", "CSS", "Giphy API", "ResponsiveVoicejs", "Github Pages"
      ],
      "github": "https://github.com/jMac029/bad-libs",
      "livesite": "https://jmac029.github.io/bad-libs/"   
    },
    {
      "id": 10,
      "title": "Train Scheduler",
      "role": "Full Stack Developer",
      "task": "built out the back-end and API calls to Giphy along with logic for questions, connected up to the front-end, and deployment.",
      "description": "A fun take on the class Mad Libs game now with the power of Giphy.",
      "tags": [
        "Firebase", "Bootstrap", "jQuery", "Github Pages"
      ],
      "tech": [
        "Firebase", "Bootstrap", "jQuery", "HTML", "CSS", "Github Pages"
      ],
      "github": "https://github.com/jMac029/train-scheduler",
      "livesite": "https://jmac029.github.io/train-scheduler/"   
    },
    {
      "id": 11,
      "title": "Giftastic Search",
      "role": "Full Stack Developer",
      "task": "built from the ground up utilizing the Giphy API and Bootstrap along with deployment.",
      "description": "Quick dynamic searching of all that Giphy has to offer.",
      "tags": [
        "Bootstrap", "jQuery", "Github Pages", "Giphy API"
      ],
      "tech": [
        "Bootstrap", "jQuery", "HTML", "CSS", "Giphy API","Github Pages"
      ],
      "github": "https://github.com/jMac029/giftastic-search",
      "livesite": "https://jmac029.github.io/giftastic-search/"   
    },
    {
      "id": 12,
      "title": "Jeopardy Trivia Game",
      "role": "Full Stack Developer",
      "task": "built from the ground up utilizing vanilla tech.",
      "description": "Jeopardy style Trivia game with Web Development theme.",
      "tags": [
        "HTML", "CSS", "jQuery", "jQuery UI", "Github Pages"
      ],
      "tech": [
        "HTML", "CSS", "jQuery", "jQuery UI", "Github Pages"
      ],
      "github": "https://github.com/jMac029/TriviaGame",
      "livesite": "https://jmac029.github.io/TriviaGame/"   
    },
    {
      "id": 12,
      "title": "Star Wars Hangman",
      "role": "Full Stack Developer",
      "task": "built from the ground up utilizing vanilla tech.",
      "description": "Modern take on a the classic Hangman game with a Star Wars theme.",
      "tags": [
        "HTML", "CSS", "Javascript", "Github Pages"
      ],
      "tech": [
        "HTML", "CSS", "Vanilla Javascript", "Github Pages"
      ],
      "github": "https://github.com/jMac029/hangman-game",
      "livesite": "https://jmac029.github.io/hangman-game/"   
    },
    {
      "id": 13,
      "title": "Bear & Panda Sticker Pack",
      "role": "iOS Developer",
      "task": "built out the back-end along with implementing in-app purchases and deployment to iOS App Store.",
      "description": "fun stickers featuring Bear & Panda for your iMessages!",
      "tags": [
        "iOS", "Xcode", "iMessages", "Mobile Development", "Swift"
      ],
      "tech": [
        "iOS", "Xcode", "Swift", "iTunes-Connect", "App Store Search Ads"
      ],
      "github": "https://bitbucket.org/james_mcmillan/bearpartystickers",
      "livesite": "https://itunes.apple.com/us/app/bear-panda-stickers/id1141248889?ls=1&mt=8"   
    },
    {
      "id": 13,
      "title": "Kosmos Insurance",
      "role": "Wordpress Developer & Designer",
      "task": "",
      "description": "local business website with interactive forms",
      "tags": [
        "Wordpress", "GoDaddy", "Local Business", "Freelance", "PHP", "MySQL"
      ],
      "tech": [
        "Wordpress", "GoDaddy", "PHP", "MySQL", "HTML", "CSS"
      ],
      "github": "https://bitbucket.org/james_mcmillan/bearpartystickers",
      "livesite": "https://kosmosinsurance.com/"   
    },


]
