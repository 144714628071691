import React from "react";
import Header from "./Header/Header";
import Nav from "./Nav/Nav";

const notFound = () => (
  <div className="wrapper">
    <Nav />
    <Header />
    <h1>Page Not Found!</h1>
  </div>
);

export default notFound;
