module.exports = [
    {
      "id": 12,
      "tech": "linux (CentOS)",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 7  
    },
    {
      "id": 13,
      "tech": "systems administration",
      "skill_level": 8,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 10  
    },
    {
      "id": 14,
      "tech": "Amazon Web Services",
      "skill_level": 3,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 2  
    },
    {
      "id": 15,
      "tech": "docker",
      "skill_level": 3,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 1  
    },
    {
      "id": 16,
      "tech": "microservices",
      "skill_level": 3,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 1  
    },
    {
      "id": 22,
      "tech": "firebase",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 1  
    },
    {
      "id": 23,
      "tech": "git",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 7  
    },
    {
      "id": 24,
      "tech": "github",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 7  
    },
    {
      "id": 25,
      "tech": "bitbucket",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 26,
      "tech": "Python",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 5  
    },
    {
      "id": 28,
      "tech": "Ruby",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 27,
      "tech": "Nginx",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 7  
    },
    {
      "id": 1,
      "tech": "GitLab",
      "skill_level": 6,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 2,
      "tech": "Nodejs",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core", "MERN stack"
      ],
      "years": 1  
    },
    {
      "id": 3,
      "tech": "Linux Ubuntu",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core", "MERN stack"
      ],
      "years": 1  
    },
    {
      "id": 4,
      "tech": "MongoDB",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core", "MERN stack"
      ],
      "years": 1  
    },
    {
      "id": 5,
      "tech": "MySQL",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core", "MERN stack"
      ],
      "years": 5  
    },
    {
      "id": 6,
      "tech": "Jenkins",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core", "MERN stack"
      ],
      "years": 1  
    },
    {
      "id": 7,
      "tech": "Jira",
      "skill_level": 3,
      "tags": [
        "web", "fundamentals", "core", "mobile"
      ],
      "years": 1  
    },
    {
      "id": 8,
      "tech": "Kubernetes",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 5  
    },
    {
      "id": 9,
      "tech": "Docker",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 5  
    },
    {
      "id": 10,
      "tech": "Harness",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 5  
    },
    {
      "id": 11,
      "tech": "wordpress",
      "skill_level": 7,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 5  
    },
    {
      "id": 27,
      "tech": "Scrum",
      "skill_level": 5,
      "tags": [
        "web", "fundamentals", "core"
      ],
      "years": 1  
    },
    {
      "id": 17,
      "tech": "Serverless",
      "skill_level": 5,
      "tags": [
        "mobile", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 18,
      "tech": "Infrastructure as Code",
      "skill_level": 5,
      "tags": [
        "mobile", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 19,
      "tech": "PagerDuty",
      "skill_level": 7,
      "tags": [
        "mobile", "fundamentals", "core"
      ],
      "years": 3  
    },
    {
      "id": 20,
      "tech": "Chef",
      "skill_level": 5,
      "tags": [
        "mobile", "fundamentals", "core"
      ],
      "years": 5  
    },
    
    


]
