import React, {Component} from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "../../css/style.css";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div className="wrapper">
        <Nav/>
        <Header/>
        <div className="container">
          <h1 className="page-header">about</h1>
          <div className="bio">
            <p>
              I am a multi-talented, highly motivated and proven technologist with nearly a
              decade of experience in Information Technology as a Systems Administrator in the
              Higher Education and Adversting industries. I am also a lifelong tinkerier,
              always deconsructing things to see how they are built and how to fix them.
            </p>
            <p>
              Recently, I have completed a certificate program in Full Stack Web Development
              expanding skills and knowledge. Now armed with technologies such as JavaScript,
              React, MongoDB, Node and Express (also known as the MERN stack), I am ready to
              take on the budding tech industry in Orange County, CA.
            </p>
            <p>
              I have also recently undertaken moving all my knowledge and experience into the
              Cloud with Amazon Web Services and will soon obtain my Solutions Architect
              Associates Certification.
            </p>
            <p>
              Along with experience in administering Linux web servers with Nginx, MySQL and
              PHP to serve dozens of web properties to millions of visitors annually, I am
              passionate about cloud technologies and microservices with the ability to scale
              applications to meet the demands of customers and the companies that serve them.
            </p>
            <p>
              Outside of technology I like to unplug and get outdoors to go hiking and camping
              when possible. I also enjoy photography, astronomy and restoring my old VW
              Westfalia. To learn more about me visit his social media accounts found on the
              <a href="/contact">
                contact</a>
              page.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
