import React from "react";
import TechItems from "./Tech-Items";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "../../css/style.css";
import "./Technologies.css";
import techdata from "../../data/tech_items.js";

const Technologies = () => {
  return (
    <div className="wrapper">
      <Nav />
      <Header />
      <div className="container">
        <h1 className="page-header"> technologies </h1>
        <TechItems techdata={techdata} />
      </div>
    </div>
  );
};

export default Technologies; 