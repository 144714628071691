import React, { Component } from "react";
import Header from "./Header/Header";
import Nav from "./Nav/Nav";
import Home from "./Home/Home";

class App extends Component {
  render() {
    return (
      <div id="outer-container">
        <div id="page-wrap">
          <Nav />
          <Header />
          <Home />
        </div>
      </div>
    );
  }
}

export default App;
