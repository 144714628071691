import React, {Component} from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "../../css/style.css";
import "./Background.css";

class Background extends Component {
  render() {
    return (
      <div className="wrapper">
        <Nav/>
        <Header/>
        <div className="container">
          <h1 className="page-header">background</h1>
          <div className="background-bio">
            <p>
            Cloud and DevOps Engineer with an extensive background in information technology. Certified in AWS with expert knowledge of Linux, CI/CD Pipelines, Serverless Application and Chef. Always keeping abreast of the changing technological landscape to expand my skill sets and experience.
            </p>
            <hr className="breaker"/>
            <h2>professional</h2>
            <h4>
              Lead AWS Cloud Value Architect | Cloudticity - Seattle, WA (remote) | October 2022 - present
            </h4>
            <p>Managed a team of 4-7 Cloud Architects specializing in Healthcare IT workloads in AWS and Azure</p>
            <p>Achieved higher efficiency across the Delivery team by implementing Time Tracking systems</p>
            <p>Automated Cloud Reporting processes for customers, improving service delivery and transparency</p>
            <p>Led technical strategy for healthcare-specific cloud implementations</p>
            <p>Served as primary technical point of contact for enterprise healthcare clients</p>
            <p>Personnel oversight and performance reviews along with hiring of architects and engineers</p>
            <p>Integration with Sales team as Pre-Sales Solutions Architect, improving technical sales process</p>
            <h4>
              Lead Cloud Architect | Onica, A Rackspace Technology Company - Irvine, CA (remote) | 2021 -
              2022
            </h4>
            <p>Leading day to day operations of a Pod of 4-6 engineers</p>
            <p>Architecting and designing technical solutions for dozens customers</p>
            <p>Project management, technical lead in client meetings, Jira task management, scoping and Long Range Planning</p>
            <p>Code Review and Mentoring of Engineers across the entire organization</p>
            <p>Point of escalation in on-call  incident handling</p>
            <p>Personnel overseeing and performance reviews along with hiring and testing of new engineers</p>
            <p>Member of various tech committees to establish standards and practices across the company</p>
            <h4>
              Senior Cloud Engineer | Onica, A Rackspace Technology Company - Irvine, CA | August 2018 -
              2021
            </h4>
            <p>Building and Maintaining AWS infrastructures for multiple clients</p>
            <p>CI/CD Pipeline development and improvement on multiple platforms, reducing deployment times by over 50%</p>
            <p>On-Call Monitoring of client infrastructure and systems within AWS</p>
            <p>Project management for progressive improvement</p>
            <p>Implementation and Improvement of Disaster Recovery solutions for multiple clients</p>
            <p>Code Review and Mentoring of Junior Engineers, Team leading and sprint planning</p>
            <p>Cost Optimization of AWS Services and usage, saving $10,000’s annually for multiple clients</p>
            <h4>
              Systems Administrator | UC Humanities Research Insitute - Irvine, CA | April 2011 -
              August 2018
            </h4>
            <p>
              Linux System Administration for Web servers handling millions of requests
              annually
            </p>
            <p>Migrated key business infrastructure to Google Suite</p>
            <p>Boosted performance across all web properties with 99.89% uptime</p>
            <p>Server automation through Bash and Python scripting for improved availability
              and security</p>
            <p>Front-end development, maintenance and deployment of multiple WordPress
              applications</p>
            <h4>
              I.T. Support Specialist | Ignited - El Segundo, CA | 2010-2011
            </h4>
            <h4>
              I.T. Systems Administrator | The Traffic Agency - El Segundo, CA | 2008-2010
            </h4>
            <hr className="breaker"/>
            <h2>certifications</h2>
            <h4>AWS Certified Solutions Architect - Associate</h4>
            <h4>AWS Certified SysOps Administrator - Associate</h4>
            <h4>AWS Certified Devloper - Associate</h4>
            <hr className="breaker"/>
            <h2>education</h2>
            <h4>B.A. Communication Studies | Sonoma State University, 2005</h4>
            <h4>
              Full Stack Web Development Coding Bootcamp | UC Irvine, 2018
            </h4>
            <hr className="breaker"/>
            <h2>personal achievements</h2>
            <h4>Eagle Scout | Boy Scouts of America | 2000</h4>
            <h4>Co-Founder | SSUTV | 2003</h4>
            <h4>Member of Phi Delta Theta International Fraternity</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Background;
