import React, { Component } from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import ProjectItems from "./Project-Items";
import "../../css/style.css";
import "./Projects.css";
import projectsdata from "../../data/project_items.js";

class Projects extends Component {
  render() {
    return (
      <div className="wrapper">
        <Nav />
        <Header />
        <div className="container">
          <h1> projects </h1>
          <ProjectItems projectsdata={projectsdata} />
        </div>
      </div>
    );
  }
}

export default Projects;
