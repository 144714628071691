import React from "react";
import { Link } from "react-router-dom";
import "../../css/style.css";
import "./Home.css";

const Home = () => {
  return (
    <div className="container">
      <ul className="homenav">
        <li>
          <Link to="/about" className="menu-item">
            about
          </Link>
        </li>
        <li>
          <Link to="/background" className="menu-item">
            background
          </Link>
        </li>
        <li>
          <Link to="/technologies" className="menu-item">
            technologies
          </Link>
        </li>
        <li>
          <Link to="/projects" className="menu-item">
            projects
          </Link>
        </li>
        <li>
          <Link to="/contact" className="menu-item">
            contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Home;
