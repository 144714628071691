import React from "react";
import "../../css/style.css";
import "./Header.css";

class Header extends React.Component {
  render() {
    return (
      <header className="top">
        <h1 className="name-header">james</h1>
        <h1 className="name-header">mcmillan</h1>
        <p className="tagline">
          <span>Lead Cloud Architect</span>
        </p>
      </header>
    );
  }
}

export default Header;
